import React, { useEffect, useState } from "react"
import { Button, Input } from "reactstrap"
import { useSelector, useDispatch } from "react-redux"

import AlreadyHaveAccountBanner from "./alreadyHaveAccountBanner"
import Layout from "../../components/layout"
import NewAccount from "./newAccount"
import SEO from "../../components/seo"
import { Role } from "../../interfaces/role"
import { classroomSelector, ClassroomState, fetchClassroomForJoinCodeAction, unsetClassroomForJoinCodeAction } from "../../hasura/slices/classroom"
import { searchQueryParams } from "../../lib/helpers"

export default function SignUpStudent() {
  const dispatch = useDispatch()

  const [isIndividual, setIsIndividual] = useState(false)
  const [joinCode, setJoinCode] = useState<string | undefined>()

  const { classroomForJoinCode }: ClassroomState = useSelector(classroomSelector)

  const searchClassroom = (code: string) => dispatch(fetchClassroomForJoinCodeAction(code!))

  useEffect(() => {
    dispatch(unsetClassroomForJoinCodeAction())

    const code = searchQueryParams("code")
    if (!code) return

    setJoinCode(code)
    searchClassroom(code)
  }, [])

  return (
    <Layout>
      <SEO title="Student Sign Up" />

      {isIndividual || classroomForJoinCode ? (
        <NewAccount isStudent={!isIndividual} role={Role.Student} isIndividual={false} />
      ) : (
        <div className="text-center pt-4">
          <h1 className="bold text-xxl text--primary text-capitalize">Student Sign-Up</h1>

          <div className="border rounded bw-2 mt-4 mb-3 p-4" style={{ width: "400px", margin: "0 auto" }}>
            <h4 className="bold text-primary mb-1">Enter your class code</h4>

            <p className="text-s mb-4 text-muted">
              Your teacher will provide this.
              <br />
              <span className="bold pr-1">IMPORTANT:</span> Make sure to include dashes (-).
            </p>

            <div className="d-flex">
              <Input
                autoCapitalize="none"
                autoFocus
                className="m-0 mr-2 data-hj-allow"
                onChange={(e) => setJoinCode(e.target.value)}
                placeholder="ex. happy-argon-2"
              />

              <Button color="primary" disabled={!joinCode} onClick={() => searchClassroom(joinCode!)}>
                Continue
              </Button>
            </div>
          </div>

          <p onClick={() => setIsIndividual(true)} className="text-m text--gray8 hover-underline text-link">
            I don't have a class code
          </p>

          <AlreadyHaveAccountBanner />
        </div>
      )}
    </Layout>
  )
}
